/* Animación del texto que aparece */
.initMessage-animation {
  font-size: 18px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  font-family: "Courier New";
  border-right: 5px solid #24ad4d;
  animation: typing 2s steps(35) 0.5s 1 normal both, blink 1s steps(1) infinite;
}

.initMessage {
  height: 35px;
}

.initMessage h3 {
  color: #444444;
  text-align: center;
  margin-bottom: 30px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
