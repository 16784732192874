/* Estilo que se aplica mientras la pantalla está en orientación landscape*/

.rotate {
  animation: rotate-device 10s linear infinite;
  -o-animation: rotate-device 10s linear infinite;
  -moz-animation: rotate-device 10s linear infinite;
}
@keyframes rotate-device {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-moz-keyframes rotate-device {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-o-keyframes rotate-device {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
