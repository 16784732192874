/* Estilos para componentes varios */

.active-img-selector {
  opacity: 1 !important;
  padding: 2px !important;
  transform: scale(1.08);
  border: 1px solid #5cb67f !important;
  box-shadow: 0 2px 2px #5cb67f;
}

/* Quitar las flechas en los inputs tipo number*/
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spin {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* Efecto para animar la imagen del menú */
.image-container:hover img {
  transform: scale(1.1);
}

/* Fondo para los pedidos en la comanda */
.note-background {
  height: 150px;
  cursor: pointer;
  margin: 10px 5px;
  position: relative;
  padding: 0 0 10px 0;
  border: 1px solid #ccc;
  transition: transform 0.3s;
  background-size: 100% 20px;
  box-shadow: 0 4px 4px #3535351a;
  background-image: linear-gradient(to bottom, #e9f3fa 1px, transparent 1px);
}

.note-background:hover {
  transform: translateY(-10px);
}

/* Fondo para los pedidos en la comanda */
.note-background2 {
  height: auto;
  cursor: pointer;
  margin: 20px 5px 5px 5px;
  position: relative;
  padding: 0 0 10px 0;
  border: 1px solid #ccc;
  background-size: 100% 20px;
  transition: transform 0.3s;
  box-shadow: 0 4px 4px #3535351a;
  background-image: linear-gradient(to bottom, #e9f3fa 1px, transparent 1px);
}

/* Fondo para los pedidos en la comanda */
.note-background3 {
  height: 90%;
  border-radius: 3px;
  background: #fff;
  position: relative;
  padding: 0 0 10px 0;
  background-size: 100% 20px;
  box-shadow: 0 4px 4px #3535351a;
}

.note-background2:hover {
  transform: translateY(-10px);
}

.spring {
  left: 4px;
  width: 4px;
  top: -15px;
  height: 22px;
  position: absolute;
  border-radius: 25px;
  border: 1px solid #2c2c2c;
  background-color: #595959;
}

.spring::after {
  left: 8px;
  top: -1px;
  width: 4px;
  content: "";
  height: 22px;
  position: absolute;
  border-radius: 25px;
  border: 1px solid #2c2c2c;
  background-color: #595959;
}
