/***** Start of reset of components *****/
body {
  margin: 0;
  height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin: 0px;
}
/***** End of reset of components *****/

/***** Start general styles *****/
.clickText:hover {
  color: #000000 !important;
  text-decoration-line: underline;
}
/* Previene la selección de imágenes y texto */
.selectDisable {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
/***** End general styles *****/
