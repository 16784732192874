/* Sky and clouds */

#sky {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  background: #ffffff;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background: linear-gradient(0deg, #ffffff 30%, #c9dbe9 80%);
}

.btnClose {
  top: 0;
  right: 0;
  width: auto;
  height: 2rem;
  display: flex;
  color: #fff;
  position: absolute;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  background-color: #222121;
}

.cloud {
  width: 200px;
  height: 60px;
  background: #fff;
  position: absolute;
  border-radius: 200px;
  -moz-border-radius: 200px;
  -webkit-border-radius: 200px;
}

.cloud:before,
.cloud:after {
  left: 95px;
  top: -30px;
  content: "";
  width: 80px;
  height: 100px;
  background: #fff;
  position: absolute;
  position: absolute;
  border-radius: 100px;
  transform: rotate(30deg);
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
}

.cloud:after {
  top: -55px;
  left: 15px;
  right: auto;
  width: 120px;
  height: 120px;
}

.x1 {
  left: 5vw;
  top: 25vh;
  opacity: 0.4;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);
}

.x2 {
  top: 8vh;
  left: 15vw;
  opacity: 0.4;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);
}

.x3 {
  top: 15vh;
  left: 60vw;
  opacity: 0.5;
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.x4 {
  top: 25vh;
  left: 32vw;
  opacity: 0.75;
  transform: scale(0.75);
  -moz-transform: scale(0.75);
  -webkit-transform: scale(0.75);
}

.x5 {
  top: 35vh;
  left: 80vw;
  opacity: 0.6;
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.x6 {
  top: 45vh;
  left: 20vw;
  opacity: 0.45;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);
}

/* Vegetables*/

.vegetable {
  height: 20vmin;
  position: absolute;
  pointer-events: none;
}

.y1 {
  left: 31vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 10s linear infinite;
  -o-animation: move-vegetables 10s linear infinite;
  -moz-animation: move-vegetables 10s linear infinite;
}

.y2 {
  left: 55vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 15s linear infinite;
  -o-animation: move-vegetables 15s linear infinite;
  -moz-animation: move-vegetables 15s linear infinite;
}
.y3 {
  left: 72vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 25s linear infinite;
  -o-animation: move-vegetables 25s linear infinite;
  -moz-animation: move-vegetables 25s linear infinite;
}
.y4 {
  left: 19vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 3s linear infinite;
  -o-animation: move-vegetables 3s linear infinite;
  -moz-animation: move-vegetables 3s linear infinite;
}
.y5 {
  left: 2vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 35s linear infinite;
  -o-animation: move-vegetables 35s linear infinite;
  -moz-animation: move-vegetables 35s linear infinite;
}

.y6 {
  left: 87vw;
  top: -100px;

  opacity: 0.3;
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);

  animation: move-vegetables 5s linear infinite;
  -o-animation: move-vegetables 5s linear infinite;
  -moz-animation: move-vegetables 5s linear infinite;
}
@keyframes move-vegetables {
  0% {
    margin-top: 0px;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 1000px;
    transform: rotate(359deg);
  }
}
@-moz-keyframes move-vegetables {
  0% {
    margin-top: 0px;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 1000px;
    transform: rotate(359deg);
  }
}
@-o-keyframes move-vegetables {
  0% {
    margin-top: 0px;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 1000px;
    transform: rotate(359deg);
  }
}
