/* Vegetables*/

.vegetables {
  height: 20vmin;
  position: absolute;
  pointer-events: none;
}

.v1 {
  left: 31vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}

.v2 {
  left: 55vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}
.v3 {
  left: 72vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}
.v4 {
  left: 19vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}
.v5 {
  left: 2vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}

.v6 {
  left: 87vw;
  bottom: -50px;

  opacity: 0.3;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
}

.v12 {
  left: 45vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.8);
  -moz-transform: scale(1.8);
  -webkit-transform: scale(1.8);
}

.v11 {
  left: 63vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.v10 {
  left: 80vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.v9 {
  left: 12vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
}
.v8 {
  left: -3vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
}

.v7 {
  left: 95vw;
  bottom: -40px;

  opacity: 0.8;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
